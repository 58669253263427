import { Box, Tooltip } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { Title2 } from 'src/feature/localization/component/atom/Title2'
import HelpIcon from '@mui/icons-material/Help'

export type TitleWithTooltipProps = {
  title: string,
  tooltip?: string,
}

export const TitleWithTooltip = observer((props: TitleWithTooltipProps) => {
  return (
    <Box sx={styles.root}>
      <Title2 m={0}>{props.title}</Title2>
      <Tooltip color='secondary' title={props.tooltip}>
        <HelpIcon fontSize='small' />
      </Tooltip>
    </Box>
  )
})

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
    justifyContent: 'center',
    gap: 5,
  },
}
