import { observer } from 'mobx-react-lite'
import { ELearningCourseCategory } from '../../model/ELearningCourseCategory'
import { Avatar, Chip } from '@mui/material'
import { startCase } from 'lodash'

export type CategoryItemProp = {
  item: ELearningCourseCategory
  index: number
  selectedSubCategory: string
  clickHandler?: (item: ELearningCourseCategory) => void
}

export const CourseItem = observer(({ item, index, selectedSubCategory, clickHandler }: CategoryItemProp) => {
  return (
    <Chip
      key={index}
      avatar={<Avatar sx={styles.avatar}>{item.titleLocale.split(' ')
        .map(word => word[0])
        .join('')}</Avatar>}
      variant='outlined'
      sx={{ justifyContent: 'start', height: '1.7rem', ...(selectedSubCategory !== item.id ? { borderColor: 'secondary.main' } : { backgroundColor: 'primary.main' }) }}
      label={startCase(item.titleLocale)}
      onClick={() => clickHandler && clickHandler(item)}
    />
  )
})

const styles = {
  avatar: {
    marginLeft: '0px !important',
    padding: '0.75rem',
  },
}
