import { observer } from 'mobx-react-lite'
import { Box, Switch, Theme, Zoom } from '@mui/material'
import NightsStaySharpIcon from '@mui/icons-material/NightsStaySharp'
import LightModeSharpIcon from '@mui/icons-material/LightModeSharp'
import { themeStoreInstance } from '../../store/theme.store'
import { AppThemeMode } from '../../model/Mode'

export const ThemeButton = observer(() => {
  const handleModeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newMode: AppThemeMode = event.target.checked ? 'dark' : 'light'
    themeStoreInstance.changeMode(newMode)
  }

  return (
    <Box sx={styles.root}>
      {themeStoreInstance.mode === 'dark'
        ? <Zoom in={themeStoreInstance.mode === 'dark'}><NightsStaySharpIcon style={{ color: '#FFFACD' }} /></Zoom>
        : <Zoom in={themeStoreInstance.mode === 'light'}><LightModeSharpIcon style={{ color: '#FFA500' }} /></Zoom>
      }
      <Switch
        checked={themeStoreInstance.mode === 'dark'}
        onChange={handleModeChange}
        inputProps={{ 'aria-label': 'theme switch' }}
      />
    </Box>
  )
})

const styles = {
  root: (theme: Theme) => ({
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.text.primary,
  }),
}
