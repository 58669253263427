import { observer } from 'mobx-react-lite'
import { useParams } from 'react-router-dom'
import { AdministratorUsersPage } from './AdministratorUsersPage'

export const DepartmentUsersPage = observer(() => {
  const { levelId } = useParams()

  if (!levelId) {
    throw new Error('Missing department key')
  }

  return (
    <AdministratorUsersPage levelId={levelId} />
  )
})
