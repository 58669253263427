import { axiosInstance } from 'src/util/axios/instance.axios'
import { CourseListItem, skillGapMetrics, TeamCourseOverview, TeamMetrics, UserMetrics } from '../model/dashboard-model'

export async function getTeamCourseOverview(): Promise<TeamCourseOverview> {
  const { data } = await axiosInstance.request<TeamCourseOverview>({
    url: `/dashboard/teamcourse-overview`,
  })

  return data
}

export async function getTeamOverview(levelId: string): Promise<TeamMetrics> {
  const { data } = await axiosInstance.request<TeamMetrics>({
    url: `/dashboard/team-overview/${levelId}`,
  })

  return data
}

export async function getUserOverview(levelId: string, userId: string): Promise<UserMetrics> {
  const { data } = await axiosInstance.request<UserMetrics>({
    url: `/dashboard/user-overview/${levelId}/${userId}`,
  })

  return data
}

export async function getDepartmentCourses(levelId: string): Promise<CourseListItem[]> {
  const { data } = await axiosInstance.request<CourseListItem[]>({
    url: `/dashboard/department-courses/${levelId}`,
  })

  return data
}

export async function getSkillGapOverview(levelId: string): Promise<skillGapMetrics> {
  const { data } = await axiosInstance.request<skillGapMetrics>({
    url: `/skill-analysis/skill-gap/${levelId}`,
  })

  return data
}
