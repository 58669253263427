import { observer } from 'mobx-react-lite'
import { CategoryList } from '../component/organism/CategoryList'
// import { OtherPartners } from '../component/organism/OtherPartners'
import { useEffect, useState } from 'react'
import { Box, Slide } from '@mui/material'
import { LearningSearch } from '../component/organism/LearningSearch'
import { ModalWindow } from 'src/component/common/analog/template/ModalWindow'

export const LearningOverviewPage = observer(() => {
  // const { translation } = useLocalizationStore()
  // const { eLearning } = translation.pages
  const [isSearching, setIsSearching] = useState(false)
  const [topOffset, setTopOffset] = useState(0)

  const calculateTopOffset = () => {
    const header = document.getElementById('yohrs-header')
    
    return header ? header.offsetHeight : 0
  }

  useEffect(() => {
    setTopOffset(calculateTopOffset())
  }, [])

  return (
    <>
      <Box sx={styles.collapseWrapper}>
        {/* <AppBox sx={styles.root}>
          <PageTitle textValue={eLearning.hrefLink({})} mb='0' />
          <AppButton variant="contained" color='primary' onClick={() => setIsSearching(true)} startIcon={<SearchIcon />}>
            {eLearning.search()}
          </AppButton>
        </AppBox> */}
        <CategoryList />
        {/* <OtherPartners /> */}
      </Box>
      <ModalWindow
        modalProps={{
          TransitionComponent: Slide,
          container: document.getElementById('yohrs-content'),
          sx: {
            position: 'absolute',
            top: topOffset,
            zIndex: 1000,
            backdropFilter: 'blur(0px)',
          },
          open: isSearching,
          onClose: () => setIsSearching(false),
          PaperProps: {
            sx: {
              position: 'absolute',
              top: 0,
              height: '90vh',
              maxWidth: '100%',
              margin: '0px !important',
            },
          },
        }}
      >
        <LearningSearch isSearching={isSearching} />
      </ModalWindow>
    </>
  )
})

const styles = {
  root: {
    display: 'flex',
    flexDirection: { lg: 'row', md: 'row', xs: 'column' },
    justifyContent: { lg: 'space-between', md: 'space-between', xs: 'center' },
    alignItems: 'center',
    width: '100%',
  },
  collapseWrapper: {
    width: '100%',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
    padding: '0 3rem',
  },
}