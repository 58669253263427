import { axiosInstance } from '../../../util/axios/instance.axios'
import {
  getLocalizedEntry,
  getLocalizedEntryList,
  GetLocalizedEntryListRequest,
  GetLocalizedEntryRequest,
} from '../../contentful/model/Api'
import { PlatformModel } from '../../contentful/model/Contentful'
import { CourseItem } from '../model/ELearningCourse'
import { ELearningCourseCategory } from '../model/ELearningCourseCategory'
import { CourseListItem } from '../model/ELearningCourseListItem'
import { ELearningCoursePlanItem } from '../model/ELearningCoursePlanItem'

export async function getELearningUrl(): Promise<string> {
  const { data: url } = await axiosInstance.request<string>({
    url: '/e-learning/diploma',
  })

  return url
}

export async function getELearningCourseUrl(courseId: string): Promise<string> {
  const { data: url } = await axiosInstance.request<string>({
    url: `/e-learning/diploma/${courseId}`,
  })

  return url
}

export async function getCategories(): Promise<ELearningCourseCategory[]> {
  const { data } = await axiosInstance.request<ELearningCourseCategory[]>({
    url: '/e-learning/category',
  })

  return data
}

export async function getSubCategories(): Promise<any[]> {
  const { data } = await axiosInstance.request<any[]>({
    url: '/e-learning/sub-category',
  })

  return data
}

export async function getCourseSubCategory(categoryKey: string): Promise<ELearningCourseCategory[]> {
  const { data } = await axiosInstance.request<ELearningCourseCategory[]>({
    url: `/e-learning/course-subcategory/${categoryKey}`,
  })

  return data
}

export async function getPersonalCoursePlan(): Promise<ELearningCoursePlanItem[]> {
  const { data } = await axiosInstance.request<ELearningCoursePlanItem[]>({
    url: '/e-learning/course-plan',
  })

  return data
}

export async function delCoursePlanItem(id: string): Promise<void> {
  await axiosInstance.request({
    url: `/e-learning/course-plan/${id}`,
    method: 'delete',
  })
}

export async function getRecommendedCourses(lang: string): Promise<CourseListItem[]> {
  const { data } = await axiosInstance.request<CourseListItem[]>({
    url: `/e-learning/course-recommended/${lang}`,
  })

  return data
}

export async function getCoursesByCategory(category: string, skip: number, take: number, lang: string): Promise<{count: number, items: CourseListItem[], subCategories: [] }> {
  const { data } = await axiosInstance.request<{count: number, items: CourseListItem[], subCategories: [] }>({
    url: `/e-learning/course/${category}/${lang}?skip=${skip}&take=${take}`,
  })

  return data
}

export async function getAllCourses(categoryTitle: string, skip: number, take: number, lang: string): Promise<any> {
  const { data } = await axiosInstance.request<{count: number, items: CourseListItem[]}>({
    url: `/e-learning/all-courses/${categoryTitle}/${lang}?skip=${skip}&take=${take}`,
  })

  return data
}

export async function getCoursesBySearch(term: string, skip: number, take: number, controller: AbortController, selectedFilter: ELearningCourseCategory[], lang: string): Promise<{items: CourseListItem[], subCategories: []}> {
  const { data } = await axiosInstance.request<{items: CourseListItem[], subCategories: []}>({
    url: `/e-learning/course-search/${lang}?skip=${skip}&take=${take}`,
    method: 'post',
    data: { term, selectedFilter },
    signal: controller.signal,
  })

  return data
}

export async function getCourse(courseId: string): Promise<CourseItem> {
  const { data } = await axiosInstance.request<CourseItem>({
    url: `/e-learning/course/${courseId}`,
  })

  return data
}

export async function putFavoriteCourse(courseId: string): Promise<void> {
  await axiosInstance.request({
    url: `/e-learning/course/${courseId}/favorite`,
    method: 'put',
  })
}

export async function getEPlatformList(request: GetLocalizedEntryListRequest) {
  return getLocalizedEntryList<PlatformModel>({
    locale: request.locale,
    content_type: 'ePlatform',
  })
}

export async function getEPlatformEntity(request: GetLocalizedEntryRequest) {
  return getLocalizedEntry<PlatformModel>(request)
}
