import { Palette } from '../../../model/Palette'

export const darkModePalette = {
  line: Palette.BRIGHT_GREY_2,
  get divider() {
    return this.line
  },
  primary: {
    main: Palette.HAN_BLUE,
    lighter: Palette.HAN_PURPLE,
    light: Palette.HAN_PURPLE_DARK,
    dark: Palette.BLACK_OUT,
  },
  error: {
    main: Palette.DANGER,
  },
  background: {
    default: Palette.BLACK_OUT,
    grey: Palette.GOSHAWK_GREY,
    paper: Palette.BLACK_OUT,
    alternate: Palette.HAN_BLUE_LIGHT,
    light: Palette.SLATE_TEAL,
    secondary: Palette.WASH_ME,
  },
  text: {
    primary: Palette.BRIGHT_GREY_2,
    secondary: Palette.BRIGHT_GREY,
    dark: Palette.BLACK_OUT,
    alternate: Palette.SLATE_TEAL,
    light: Palette.GOSHAWK_GREY,
  },
  grey: {
    0: Palette.GOSHAWK_GREY_ALPHA_60,
    0.5: Palette.GOSHAWK_GREY_ALPHA_50,
    1: Palette.GOSHAWK_GREY_ALPHA_40,
    2: Palette.BLACK_OUT_ALPHA_50,
    3: Palette.BLACK_OUT_ALPHA_40,
    4: Palette.BLACK_OUT,
    5: Palette.WHITE,
    6: Palette.CHEFS_HAT,
    7: Palette.WASH_ME,
    8: Palette.BRIGHT_GREY_2,
  },
  progress: {
    primary: Palette.MIDNIGHT_PINE,
    secondary: Palette.SLATE_TEAL,
  },
}
