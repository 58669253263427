import { Box, Collapse, Theme } from '@mui/material'
import useEmblaCarousel from 'embla-carousel-react'
import { observer } from 'mobx-react-lite'
import { ChildrenProps } from 'src/model/React'
import { AppBox } from '../AppBox'
import { CarouselProgressBar } from './CarouselprogressBar'
import { CarouselButtons } from './CarouselButtons'
import { CarouselDots } from './CarouselDots'

interface CarouselProps extends ChildrenProps {
  showProgressBar?: boolean;
  showDots?: boolean;
}

export const AppCarousel = observer(({ children, showProgressBar = true, showDots = false }: CarouselProps) => {
  const [emblaRef, emblaApi] = useEmblaCarousel({
    breakpoints: {
      '(min-width: 600px)': {
        slidesToScroll: 1,
      },
      '(min-width: 960px)': {
        slidesToScroll: 2,
      },
      '(min-width: 1280px)': {
        slidesToScroll: 3,
      },
    },
  })

  return (
    <Box sx={styles.root}>
      <Collapse in={showDots}>
        <CarouselDots emblaApi={emblaApi} />
      </Collapse>
      <AppBox sx={styles.carouselContainer} ref={emblaRef}>
        <Box sx={styles.carouselElement}>{children}</Box>
      </AppBox>
      <CarouselButtons emblaApi={emblaApi} />
      <Collapse in={showProgressBar}>
        <CarouselProgressBar emblaApi={emblaApi} />
      </Collapse>
    </Box>
  )
})

const styles = {
  root: ({ handleCssUnit }: Theme) => ({
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    gap: handleCssUnit(10),
    cursor: 'pointer',
    overflow: 'hidden',
  }),
  carouselContainer: {
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
  },
  carouselElement: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: 10,
    minHeight: '10px',
    minWidth: '10px',
  },
}
