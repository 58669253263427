import { GenericStoreAsyncMethod } from 'src/util/mobx/generic-store/decorator/async-method.decorator'
import { GenericStore } from 'src/util/mobx/generic-store/generic.store'
import { createStore } from 'src/util/mobx/generic-store/store-creator'
import { getDepartmentCourses, getSkillGapOverview, getTeamCourseOverview, getTeamOverview, getUserOverview } from '../api/dashboard'
import { CourseListItem, skillGapMetrics, SkillGapOverview, TeamCourseOverview, TeamMetrics, TeamOverview, UserMetrics, UserOverview } from '../model/dashboard-model'
import { getAllCourses } from 'src/feature/e-learning/api/e-learning'

export class dashboardStore extends GenericStore {
  teamCourseOverview: TeamCourseOverview | undefined
  teamOverview: TeamOverview = {}
  userOverview: UserOverview = {}
  departmentCourses: CourseListItem[] = []
  skillGapOverview: SkillGapOverview = {}

  constructor() {
    super('dashboardStore')

    super.observe(this)
    super.persist({ encrypt: false })
  }

  reset() {
    this.teamCourseOverview = undefined
    this.teamOverview = {}
    this.userOverview = {}
    this.departmentCourses = []
    this.skillGapOverview = {}
  }

  @GenericStoreAsyncMethod()
  async getTeamCourseOverview() {
    const data: TeamCourseOverview = await getTeamCourseOverview()
    this.teamCourseOverview = data
  }

  @GenericStoreAsyncMethod()
  async getTeamOverview(levelId: string) {
    const data: TeamMetrics = await getTeamOverview(levelId)
    this.teamOverview[levelId] = data
  }

  @GenericStoreAsyncMethod()
  async getRandomCategoryCourses(categoryTitle: string, lang: string) {
    const data = await getAllCourses(categoryTitle, 0, 6, lang)
    
    return data.items
  }

  @GenericStoreAsyncMethod()
  async getUserOverview(levelId: string, userId: string) {
    const data: UserMetrics = await getUserOverview(levelId, userId)
    this.userOverview[userId] = data
  }

  @GenericStoreAsyncMethod()
  async getDepartmentCourses(levelId: string) {
    const data: CourseListItem[] = await getDepartmentCourses(levelId)
    this.departmentCourses = data
  }

  @GenericStoreAsyncMethod()
  async getSkillGapOverview(levelId: string) {
    const data: skillGapMetrics = await getSkillGapOverview(levelId)
    this.skillGapOverview[levelId] = data
  }
}

export const {
  storeInstance: dashboardStoreInstance,
  useStore: useDashboardStore,
} = createStore(new dashboardStore())
