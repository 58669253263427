import { Box, Collapse, Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { AppProgressLine } from 'src/component/common/analog/molecule/loader/AppProgressLine'
import { useSkillAssessmentStore } from 'src/feature/skill-assessment/store/skill-assessment.store'
import { Palette } from 'src/feature/theme/model/Palette'
import { useUserStore } from 'src/feature/user/store/user.store'

export const GetStarted = observer(() => {
  const { userData } = useUserStore()
  const skillAssessmentStore = useSkillAssessmentStore()
  const { isLoading } = skillAssessmentStore.getGenericFieldsState('getSkillSets')

  useEffect(() => {
    skillAssessmentStore.getSkillSets()
  }, [])

  return (
    <Box sx={styles.root}>
      <Typography style={{ fontSize: '2rem', fontWeight: 400 }}>{`Welcome back, ${userData?.firstName || 'User'}!`}</Typography>
      <Typography>{'We admire your skills, and now its time to evolve together with us, your manager and your time in self education'}</Typography>
      <AppProgressLine sx={{ width: '5rem' }} isShowing={isLoading} />
      {!isLoading && !skillAssessmentStore.skillSets?.category_description && <Collapse in={true}>
        <Typography sx={{ fontWeight: 600, color: Palette.DANGER }}>
          Your manager hasn't assigned any skills yet. Please reach out for further guidance.
        </Typography>
      </Collapse>}
    </Box>
  )
})

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 5,
    padding: { xs: '0 1rem', sm: '0 2rem', lg: '0 3rem' },
  },
}
