import { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useELearningStore } from '../../store/e-learning.store'
import { CircularProgress, Collapse, MenuItem, Select, Theme } from '@mui/material'
import { AppBox } from 'src/component/common/analog/molecule/AppBox'
import { CourseListPresentationItem } from './CourseListPresentationItem'
import { Caption } from 'src/feature/localization/component/atom/Caption'
import { useLocalizationStore } from 'src/feature/localization/store/localization.store'
import { debounce, startCase } from 'lodash'
import { AppSearchBar } from 'src/component/common/analog/molecule/AppSearchBar'
import { PageTitle } from 'src/feature/localization/component/atom/PageTitle'
import { CategoryItem } from './CategoryItem'
import { Word } from 'src/feature/localization/component/atom/Word'
import { AppInputLabel } from 'src/feature/form/component/analog/AppInputLabel'
import { CoursePresentation } from './CoursePresentation'
import { AnimatedIconButton } from 'src/component/common/analog/molecule/button/AnimatedIconButton'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { keyframes } from '@emotion/react'
import { ArrowDownward } from '@mui/icons-material'

export type LearningSearchProps = {
  isSearching: boolean
}

export const LearningSearch = observer((props: LearningSearchProps) => {
  const eLearningStore = useELearningStore()
  const { translation, locale } = useLocalizationStore()
  const texts = translation.pages.eLearning
  const { isLoading } = eLearningStore.getGenericFieldsState('search')
  const [currentSearch, setCurrentSearch] = useState<string>('')
  const pageSize = 50
  const [selectedCategory, setSelectedCategory] = useState<string>('')
  const [currentSubCategory, setCurrentSubCategory] = useState<string>('')
  const [filteredSubCategories, setFilteredSubCategories] = useState<any>([])
  const [selectedCourseId, setSelectedCourseId] = useState<string>('')
  // const [favoriteUpdate, setFavoriteUpdate] = useState<boolean>(false)

  useEffect(() => {
    eLearningStore.loadSubCategoriesIfNeeded()
    eLearningStore.clearSearch()
    eLearningStore.selectedFilters = []
  }, [])

  const handleSearch = debounce(async (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedCourseId('')
    if (event.target.value.length > 1) {
      setCurrentSearch(event.target.value)
      await eLearningStore.search(event.target.value, 0, pageSize, locale)
    } else if (event.target.value.length === 0) {
      setSelectedCategory('all')
      setCurrentSubCategory('all')
      setFilteredSubCategories([])
      await clearSearch()
    }
  }, 750)

  const clearSearch = async () => {
    setCurrentSearch('')
    await eLearningStore.clearSearch()
  }

  const extendSearchResults = async () => {
    if (eLearningStore.searchList) {
      await eLearningStore.appendSearch(currentSearch, eLearningStore.searchList.length, pageSize, locale)
    }
  }

  const changeCategory = async (categoryId: string) => {
    setSelectedCourseId('')
    setSelectedCategory(categoryId)
    setCurrentSubCategory('all')
    setFilteredSubCategories([])
    const filteredSubs = eLearningStore.categoriesWithSubs.filter((subCategory: any) => subCategory.category.id === categoryId).map((subCategory: any) => subCategory.subCategory)
    setFilteredSubCategories(filteredSubs)
    eLearningStore.selectedFilters = filteredSubs
    categoryId === 'all' && currentSearch.length === 0 ? await clearSearch() : await searchTerm(currentSearch)
  }

  const selectedSubCategories = async (subCategory: any) => {
    setSelectedCourseId('')
    setCurrentSubCategory(subCategory)
    if (subCategory === 'all') {
      eLearningStore.selectedFilters = eLearningStore.categoriesWithSubs.filter((subCategory: any) => subCategory.category.id === selectedCategory).map((subCategory: any) => subCategory.subCategory)
    } else {
      eLearningStore.selectedFilters = [subCategory]
    }
    await searchTerm(currentSearch)
  }

  const searchTerm = async (term: string) => {
    await eLearningStore.search(term, 0, pageSize, locale)
  }

  const handleCourseSelection = (courseId: string) => {
    setSelectedCourseId(courseId)
  }

  return (
    <AppBox sx={styles.mainSection} >
      <AppBox sx={styles.upperSection}>
        <PageTitle textValue={texts.searchTitle} />
        <AppBox sx={styles.filterContainer}>
          <AppBox sx={styles.selectionContainer}>
            <AppInputLabel id='filter_by_category' textValue={texts.filterCategory} sx={{ fontSize: '1.1em', fontWeight: 200 }} />
            <Select
              labelId='filter_by_category'
              sx={styles.subCategoryFilter}
              value={selectedCategory || 'all'}
              onChange={event => changeCategory(event.target.value)}
            >
              <MenuItem key='all' value='all' defaultValue='all'>All</MenuItem>
              {eLearningStore.categories && eLearningStore.categories.map(category => (
                <MenuItem key={category.id} value={category.id}>{startCase(category.titleLocale)}</MenuItem>
              ))}
            </Select>
            <AppInputLabel id='filter_by_subcategory' textValue={texts.filterSubCategory} sx={{ fontSize: '1.1em', fontWeight: 200 }} />
            <Select
              labelId='filter_by_subcategory'
              sx={styles.subCategoryFilter}
              value={currentSubCategory || 'all'}
              onChange={event => selectedSubCategories(event.target.value)}
            >
              <MenuItem key='all' value='all' defaultValue='all'>All</MenuItem>
              {filteredSubCategories && filteredSubCategories.map((subCategory: any) => (
                <MenuItem key={subCategory.id} value={subCategory}>{startCase(subCategory.titleLocale)}</MenuItem>
              ))}
            </Select>
          </AppBox>
        </AppBox>
        <AppBox sx={styles.searchbarContainer}>
          <AppSearchBar onChange={handleSearch} />
          {isLoading && <CircularProgress size={50} />}
        </AppBox>
      </AppBox>
      {props.isSearching && <AppBox sx={styles.lowerSection}>
        {!isLoading && eLearningStore.searchList && eLearningStore.searchList.length === 0 && <Caption textValue={texts.noSearchHit} />}
        {!isLoading && eLearningStore.searchList && eLearningStore.searchList.length !== 0 && eLearningStore.subCategories && selectedCourseId && <AppBox sx={styles.coursePresentationWrapper}>
          <AppBox sx={styles.iconContainer} onClick={() => setSelectedCourseId('')}>
            <AnimatedIconButton
              className="icon"
              sx={styles.iconButton}
              color='primary'
            >
              <ArrowBackIcon />
            </AnimatedIconButton>
            <Word textValue={texts.backToSearchCourses} sx={styles.wordWrapper} />
          </AppBox>
          <CoursePresentation courseId={selectedCourseId} />
        </AppBox>}
        <AppBox sx={styles.coursePresentationItemWrapper}>
          {!isLoading && !selectedCourseId && eLearningStore.searchList && eLearningStore.searchList.length !== 0 && eLearningStore.searchList.map((course: any) => (
            <CourseListPresentationItem item={course} key={course.id} courseSelectedFlag={true} courseSelectedId={handleCourseSelection} />
          ))}
        </AppBox>
        {!isLoading && eLearningStore.searchList && eLearningStore.searchList.length !== 0 && eLearningStore.subCategories && !selectedCourseId && (
          <AppBox sx={styles.viewMoreContainer} onClick={extendSearchResults}>
            <Word textValue='View More' sx={styles.wordWrapper} />
            <AnimatedIconButton
              className="icon"
              sx={styles.iconButton}
              color='primary'
            >
              <ArrowDownward />
            </AnimatedIconButton>
          </AppBox>
        )}

      </AppBox>}
      <Collapse in={props.isSearching && !eLearningStore.searchList}>
        <AppBox sx={styles.learningCategory}>
          {eLearningStore.categories && eLearningStore.categories.map((category, index) => <CategoryItem key={category.id} item={category} index={index} />)}
        </AppBox>
      </Collapse>
    </AppBox>
  )
})

const moveRight = keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-5px);
  }
`

const moveDown = keyframes`
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(5px);
  }
`

const styles = {
  mainSection: {
    flexDirection: 'column',
    maxHeight: '100vh',
  },
  upperSection: ({ handleCssUnit }: Theme) => ({
    gap: handleCssUnit(5),
    flexDirection: 'column',
    alignItems: 'center',
  }),
  lowerSection: ({ handleCssUnit }: Theme) => ({
    display: 'flex',
    gap: handleCssUnit(10),
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
    flexWrap: 'wrap',
    maxWidth: '100%',
    minWidth: '100%',
  }),
  learningCategory: ({ handleCssUnit }: Theme) => ({
    display: 'flex',
    gap: handleCssUnit(10),
    flexDirection: 'row',
    justifyContent: 'center',
    flexWrap: 'wrap',
    maxWidth: '100%',
    minWidth: '100%',
  }),
  searchbarContainer: {
    display: 'flex',
    gap: '1rem',
    width: { lg: '30rem', md: '25rem', sm: '20rem' },
  },
  filterContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
  },
  selectionContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '7px',
    marginBottom: '0.3rem',
  },
  subCategoryFilter: {
    height: '32px',
    width: 'auto',
    borderRadius: '16px',
    color: 'text.primary',
  },
  coursePresentationWrapper: {
    flexDirection: 'column',
    gap: 15,
  },
  coursePresentationItemWrapper: {
    display: 'flex',
    gap: 7,
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'stretch',
  },
  iconContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    gap: 1,
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
      color: '#36BBAF',
    },
    '&:hover .icon': {
      animation: `${moveRight} 0.3s forwards`,
    },
  },
  iconButton: {
    transition: 'transform 0.3s ease-in-out, opacity 0.3s ease-in-out',
    padding: '0px',
    fontSize: '18px',
  },
  wordWrapper: {
    fontSize: '15px',
    color: '#36BBAF',
    fontWeight: 500,
  },
  viewMoreContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    gap: 1,
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
      color: '#36BBAF',
    },
    '&:hover .icon': {
      animation: `${moveDown} 0.3s forwards`,
    },
  },
}