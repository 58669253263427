import { AppBox } from '../../../../component/common/analog/molecule/AppBox'
import { observer } from 'mobx-react-lite'
import { useELearningStore } from '../../store/e-learning.store'
import { ELearningCoursePlanItem } from '../../model/ELearningCoursePlanItem'
import { CoursePlanItem } from './CoursePlanItem'
import { Title2 } from 'src/feature/localization/component/atom/Title2'
import { Word } from 'src/feature/localization/component/atom/Word'
import { AppCarousel } from 'src/component/common/analog/molecule/carousel/AppCarousel'
import { Palette } from 'src/feature/theme/model/Palette'
import { Box, Card } from '@mui/material'
import SchoolIcon from '@mui/icons-material/School'
import { AppButton } from 'src/component/common/analog/molecule/button/AppButton'
import { useNavigate } from 'react-router-dom'
import { appRoutePath } from 'src/feature/router/util/app-route-path'

export type CoursePlanListProp = {
  items: ELearningCoursePlanItem[]
  title: string
}

export const CoursePlanList = observer((props: CoursePlanListProp) => {
  const eLearningStore = useELearningStore()
  const navigate = useNavigate()

  const transform = (item: ELearningCoursePlanItem, index: number) => <CoursePlanItem item={item} index={index} key={item.id} />
  const sort = (a: ELearningCoursePlanItem, b: ELearningCoursePlanItem) => {
    if (a.dealine && !b.dealine) {
      return -1
    }

    if (b.dealine && !a.dealine) {
      return 1
    }

    if (a.dealine && b.dealine) {
      return new Date(a.dealine).getTime() - new Date(b.dealine).getTime()
    }

    if (a.progress && !b.progress) {
      return -1
    }

    if (b.progress && !a.progress) {
      return 1
    }

    if (a.progress && b.progress) {
      return b.progress.percentage - a.progress.percentage
    }

    return new Date(a.created).getTime() - new Date(b.created).getTime()
  }

  return (
    <AppBox sx={styles.container}>
      <AppBox sx={styles.titleContainer}>
        <Title2 m={0}>{props.title}</Title2>
        <Word sx={styles.pill}>{props.items?.length || 0}</Word>
      </AppBox>
      {eLearningStore.personalCourcePlan && props.items.length !== 0 && <AppCarousel showDots={true} showProgressBar={false}>
        {props.items.sort(sort).map(transform)}
      </AppCarousel>}
      {eLearningStore.personalCourcePlan && props.items.length === 0 && <Card sx={styles.card}>
        <Box sx={styles.mediaContainer}>
          <SchoolIcon sx={{ fontSize: 100, color: Palette.HAN_BLUE }} />
          <Box sx={styles.overlay} />
        </Box>
        <AppButton variant='contained' color='primary' textValue={'Courses'} sx={{ margin: '1rem', width: '80%' }} onClick={() => navigate(appRoutePath.learning)} />
      </Card>}
    </AppBox>
  )
})

const styles = {
  container: {
    flexDirection: 'column',
    overflow: 'hidden',
    borderRadius: '0.75rem',
    border: `1px solid ${Palette.HAN_BLUE}40`,
    padding: '0.75rem 0.75rem 0.4rem 0.75rem',
    gap: 5,
  },
  titleContainer: {
    alignItems: 'center',
  },
  pill: {
    marginLeft: '10px',
    borderRadius: '1rem',
    border: '1px solid',
    borderColor: 'text.primary',
    padding: '0px 12px',
    backgroundColor: 'background.default',
    color: 'text.primary',
  },
  card: {
    maxWidth: 260,
    minWidth: 260,
    cursor: 'pointer',
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '100%',
    margin: '10px 0px',
  },
  mediaContainer: {
    position: 'relative',
    width: '100%',
    aspectRatio: '16 / 9', // 16:9 aspect ratio
    overflow: 'hidden',
    borderRadius: '10px 10px 0 0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: '#008000',
    opacity: 0.5, // 50% black overlay
  },
}
