import { lazily } from 'react-lazily'
import { LazyRouteModel } from '../model/Route'
import { PlatformPage } from '../../e-learning/page/PlatformPage'
import { PlatformArticlePage } from '../../e-learning/page/PlatformArticlePage'
import { VillkorPage } from '../../villkor/page/VillkorPage'
import { LearningOverviewPage } from 'src/feature/e-learning/page/LearningOverviewPage'
import { LearningCategoryPage } from 'src/feature/e-learning/page/LearningCategoryPage'
import { SkillAnalysisPage } from 'src/feature/skill-assessment/page/SkillAssessmentPage'
import { UserDashboardPage } from 'src/feature/dashboard/page/UserDashboardPage'
import { AdministratorDasboardPage } from 'src/feature/dashboard/page/AdministratorDasboardPage'
import { DepartmentUsersPage } from 'src/feature/dashboard/page/DepartmentUsersPage'

const { BlogPage } = lazily(() => import('src/feature/blog/page/BlogPage'))
const { BlogArticlePage } = lazily(() => import('src/feature/blog/page/BlogArticlePage'))
const { ForumPage } = lazily(() => import('src/feature/forum/page/ForumPage'))
const { MaintenancePage } = lazily(() => import('src/feature/maintenance/page/MaintenancePage'))
const { PayrollPage } = lazily(() => import('src/feature/payroll/page/PayrollPage'))
const { FaqPage } = lazily(() => import('src/feature/knowledge/page/FaqPage'))
const { FaqGroupPage } = lazily(() => import('src/feature/knowledge/page/FaqGroupPage'))
const { AdditionalServicesPage } = lazily(() => import('src/feature/knowledge/page/AdditionalServicesPage'))
const { ProfileInfoPage } = lazily(() => import('src/feature/profile/page/ProfileInfoPage'))
const { SubscriptionPage } = lazily(() => import('src/feature/profile/page/SubscriptionPage'))
const { PaymentHistoryPage } = lazily(() => import('src/feature/payment/page/PaymentHistoryPage'))
const { SignUpPage } = lazily(() => import('src/feature/on-boarding/page/SignUpPage'))
const { SignInPage } = lazily(() => import('src/feature/auth/page/SignInPage'))
const { ForgotPasswordPage } = lazily(() => import('src/feature/auth/page/ForgotPasswordPage'))
const { ResetPasswordPage } = lazily(() => import('src/feature/auth/page/ResetPasswordPage'))
const { VerifyEmailPage } = lazily(() => import('src/feature/auth/page/VerifyEmailPage'))
const { SsoVerifyPage } = lazily(() => import('src/feature/auth/page/SsoVerifyPage'))
const { ActivateLearningPage } = lazily(() => import('src/feature/stripe/page/ActivateLearningPage'))
const { LoginPage } = lazily(() => import('src/feature/b2b/page/LoginPage'))
const { OverviewPage } = lazily(() => import('src/feature/control-panel/page/OverviewPage'))
const { UsersPage } = lazily(() => import('src/feature/control-panel/page/UsersPage'))
const { OrganisationPage } = lazily(() => import('src/feature/control-panel/page/OrganisationPage'))
const { ModulesPage } = lazily(() => import('src/feature/control-panel/page/ModulesPage'))
const { SystemPage } = lazily(() => import('src/feature/control-panel/page/SystemPage'))
const { EditProfilePage } = lazily(() => import('src/feature/profile/page/EditProfilePage'))
const { EditPositionsPage } = lazily(() => import('src/feature/profile/page/EditPositionsPage'))
const { CoursePage } = lazily(() => import('src/feature/e-learning/page/CoursePage'))
const { CoursePlayerPage } = lazily(() => import('src/feature/e-learning/page/CoursePlayerPage'))
const { CompanyPage } = lazily(() => import('src/feature/company-page/page/CompanyPage'))
const { CompanyPageEditItem } = lazily(() => import('src/feature/company-page/page/CompanyPageEditItem'))

export const lazyRoutes: LazyRouteModel = {
  // auth
  signIn: <SignInPage />,
  forgotPassword: <ForgotPasswordPage />,
  resetPassword: <ResetPasswordPage />,
  villkor: <VillkorPage />,
  verifyAccount: <VerifyEmailPage />,
  ssoVerify: <SsoVerifyPage />,

  // on-boarding
  signUp: <SignUpPage />,

  // blog
  blog: <BlogPage />,
  blogArticle: <BlogArticlePage />,

  // eLearning
  eLearning: <PlatformPage />,
  platformArticle: <PlatformArticlePage />,
  learning: <LearningOverviewPage />,
  learningCategory: <LearningCategoryPage />,
  learningCourse: <CoursePage />,
  course: <CoursePlayerPage />,

  // knowledge
  faq: <FaqPage />,
  faqGroup: <FaqGroupPage />,
  additionalServices: <AdditionalServicesPage />,

  // payroll
  payroll: <PayrollPage />,

  // forum
  forum: <ForumPage />,

  // profile
  profileInfo: <ProfileInfoPage />,
  editProfile: <EditProfilePage />,
  editPositions: <EditPositionsPage />,
  subscription: <SubscriptionPage />,
  paymentHistory: <PaymentHistoryPage />,
  activateLearning: <ActivateLearningPage />,

  // maintenance
  maintenance: <MaintenancePage />,

  // B2B
  login: <LoginPage />,
  controlpanelOverview: <OverviewPage />,
  controlpanelUsers: <UsersPage />,
  controlpanelModules: <ModulesPage />,
  controlpanelSystem: <SystemPage />,
  controlpanelOrganisation: <OrganisationPage />,

  // CompanyPage
  companyPage: <CompanyPage />,
  companyPageEditItem: <CompanyPageEditItem />,

  // skill analysis
  skillAssessment: <SkillAnalysisPage />,

  // dashboard
  userDashboard: <UserDashboardPage />,
  administratorDashboard: <AdministratorDasboardPage />,
  administratorUsers: <DepartmentUsersPage />,
} as const
