import { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { AppBox } from '../AppBox'
import { EmblaCarouselType } from 'embla-carousel-react'
import { AppButton } from '../button/AppButton'

export const CarouselDots = observer(({ emblaApi }: { emblaApi?: EmblaCarouselType }) => {
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [scrollSnaps, setScrollSnaps] = useState<number[]>([])

  const onSelect = () => {
    if (emblaApi) {
      setSelectedIndex(emblaApi.selectedScrollSnap())
      setScrollSnaps(emblaApi.scrollSnapList())
    }
  }

  useEffect(() => {
    if (!emblaApi) {
      return
    }
    
    onSelect()
    emblaApi.on('reInit', onSelect)
    emblaApi.on('select', onSelect)
  }, [emblaApi])

  return (
    <AppBox sx={styles.dots}>
      {scrollSnaps.map((snap, index) => (
        <AppButton
          key={snap}
          sx={{
            ...styles.dot,
            ...(index === selectedIndex ? styles.selectedDot : {}),
          }}
          onClick={() => emblaApi && emblaApi.scrollTo(index)}
        >
        </AppButton>
      ))}
    </AppBox>
  )
})

const styles = {
  dots: {
    justifyContent: 'flex-end',
    gap: '3px',
    height: '3px',
    display: { xs: 'none', md: 'flex', lg: 'flex', xl: 'flex' },
  },
  dot: {
    padding: '0.1px 5px',
    backgroundColor: 'progress.primary',
  },
  selectedDot: {
    backgroundColor: 'progress.secondary',
  },
}
