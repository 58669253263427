import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { useDashboardStore } from '../store/dashboard.store'
import { Box, Typography } from '@mui/material'
import { AppPaper } from 'src/component/common/analog/molecule/AppPaper'
import { Palette } from 'src/feature/theme/model/Palette'
import { AppButton } from 'src/component/common/analog/molecule/button/AppButton'
import { useNavigate } from 'react-router-dom'
import { appRoutePath } from 'src/feature/router/util/app-route-path'
import { Title2 } from 'src/feature/localization/component/atom/Title2'
import { AppBox } from 'src/component/common/analog/molecule/AppBox'
import { AppCarousel } from 'src/component/common/analog/molecule/carousel/AppCarousel'
import { CourseListPresentationItem } from 'src/feature/e-learning/component/organism/CourseListPresentationItem'
import { Caption } from 'src/feature/localization/component/atom/Caption'
import { useLocalizationStore } from 'src/feature/localization/store/localization.store'

export type DepartmentCoursesProps = {
  levelId: string
}

export const DepartmentCourses = observer(({ levelId }: DepartmentCoursesProps) => {
  const dashboardStore = useDashboardStore()
  const navigate = useNavigate()
  const { translation } = useLocalizationStore()
  const { isLoading } = dashboardStore.getGenericFieldsState('getDepartmentCourses')
  const texts = translation.pages.eLearning
  
  useEffect(() => {
    dashboardStore.getDepartmentCourses(levelId)
  }, [])

  return (
    <Box sx={styles.root}>
      <AppBox sx={{ flexDirection: 'column', gap: 10 }}>
        <Title2 m={0} style={{ textAlign: 'center', fontSize: '2.5rem' }}>{`Assigned to ${dashboardStore.teamOverview?.[levelId]?.organisationLevelName}`}</Title2>
        <AppBox sx={styles.courseContainer}>
          {!isLoading && dashboardStore.departmentCourses && dashboardStore.departmentCourses?.length !== 0 && <AppCarousel showDots={true} showProgressBar={false}>
            {dashboardStore.departmentCourses?.map(c => <CourseListPresentationItem item={c} key={c.id} />)}
          </AppCarousel>}
          {dashboardStore.departmentCourses && dashboardStore.departmentCourses?.length === 0 && <Caption textValue={texts.missingCourse} />}
        </AppBox>
      </AppBox>
      <AppPaper sx={styles.bannerWrapper}>
        <Typography style={{ fontSize: '2rem' }}>{`Your ${dashboardStore.teamOverview?.[levelId]?.organisationLevelName} need more courses.`}</Typography>
        <Typography>{'Make sure to browse courses and click the settings icon to add them to these and other groups or individuals.'}</Typography>
        <AppButton onClick={() => navigate(appRoutePath.learning)}>{'Assign more courses'}</AppButton>
      </AppPaper>
    </Box>
  )
})

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '4rem',
    width: '100%',
  },
  bannerWrapper: {
    padding: '5rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 15,
    backgroundColor: '#EECB11',
    color: Palette.BLACK_OUT,
    width: '100%',
  },
  courseContainer: {
    flexDirection: 'column',
    overflow: 'hidden',
    borderRadius: '0.75rem',
    border: `1px solid ${Palette.HAN_BLUE}40`,
    padding: '0.75rem 0.75rem 0.4rem 0.75rem',
    gap: 5,
  },
}
