import { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useELearningStore } from '../../store/e-learning.store'
import { ELearningCourseCategory } from '../../model/ELearningCourseCategory'
import { CategoryItem } from './CategoryItem'
import { Box, Fade, useMediaQuery } from '@mui/material'
import { AppCarousel } from 'src/component/common/analog/molecule/carousel/AppCarousel'
import { AppBox } from 'src/component/common/analog/molecule/AppBox'
import { Caption } from 'src/feature/localization/component/atom/Caption'
import { useLocalizationStore } from 'src/feature/localization/store/localization.store'
import { CourseListPresentationItem } from './CourseListPresentationItem'
import { AppButton } from 'src/component/common/analog/molecule/button/AppButton'
import { AppProgressLine } from 'src/component/common/analog/molecule/loader/AppProgressLine'
import { CategorySearch } from './CategorySearch'

export const CategoryList = observer(() => {
  const eLearningStore = useELearningStore()
  const { translation, locale } = useLocalizationStore()
  const [searchTerm, setSearchTerm] = useState('')
  const { isLoading: isLoadingAllCourses } = eLearningStore.getGenericFieldsState('getAllCourses')
  const { isLoading: isLoadingSearch } = eLearningStore.getGenericFieldsState('search')
  const isLoading = isLoadingAllCourses || isLoadingSearch
  const isSmallScreen = useMediaQuery('(max-width:900px)')
  const texts = translation.pages.eLearning
  const pageSize = 30
  
  useEffect(() => {
    clearValues()
    if (eLearningStore.categories === undefined) {
      eLearningStore.loadCategoriesIfNeeded()
    }
    eLearningStore.getAllCourses('all', 0, pageSize, locale)
  }, [])

  const clearValues = () => {
    eLearningStore.courseList = []
    eLearningStore.courseCount = 0
  }

  const transform = (category: ELearningCourseCategory, index: number) => {
    return <CategoryItem key={category.id} item={category} index={index} />
  }

  const extendResults = async () => {
    if (eLearningStore.courseList && eLearningStore.courseList.length !== eLearningStore.courseCount) {
      if (searchTerm.length > 0) {
        await eLearningStore.appendSearch(searchTerm, eLearningStore.courseList.length, pageSize, locale)
      } else {
        await eLearningStore.appendAllCourses('all', eLearningStore.courseList.length, pageSize, locale)
      }
    }
  }

  return (
    <AppBox sx={styles.root}>
      <CategorySearch setSearchTerm={setSearchTerm} />
      {!isSmallScreen && <Box sx={styles.chipContainer}>
        {eLearningStore.categories && eLearningStore.categories.map(transform)}
      </Box>}
      {isSmallScreen && <AppCarousel showProgressBar={false}>
        {eLearningStore.categories && eLearningStore.categories.map(transform)}
      </AppCarousel>
      }
      {<AppProgressLine isShowing={isLoading} />}
      {!isLoading && (eLearningStore.courseList === undefined || eLearningStore.courseList.length === 0) && <Caption textValue={texts.missingCourse} />}
      {!isLoading && eLearningStore.courseList && eLearningStore.courseList.length > 0 && (
        <Fade in={eLearningStore.courseList?.length > 0} timeout={500}>
          <AppBox sx={styles.courseList}>
            {eLearningStore.courseList.map(course => (
              <CourseListPresentationItem key={course.id} item={course} />
            ))}
          </AppBox>
        </Fade>
      )}
      {!isLoading && eLearningStore.courseList && eLearningStore.courseList.length > 0 && eLearningStore.courseList.length !== eLearningStore.courseCount && (
        <AppBox sx={{ justifyContent: 'center' }}>
          <AppButton variant='contained' color="primary" onClick={extendResults}>Load more courses</AppButton>
        </AppBox>
      )}
    </AppBox>
  )
})

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: 50,
  },
  chipContainer: {
    display: 'grid',
    gridTemplateColumns: { lg: 'repeat(6, 1fr)', md: 'repeat(5, 1fr)' },
    columnGap: 10,
    rowGap: 8,
  },
  courseList: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    columnGap: 20,
    rowGap: 15,
  },
}
