import { HeaderNavModel } from '../../../model/HeaderNav'
import { useLocalizationStore } from '../../../../localization/store/localization.store'
import { appRoutePath } from '../../../../router/util/app-route-path'
import { observer } from 'mobx-react-lite'
import { LINK_LABEL_CLASS_NAME, NavListItem } from '../../../../../component/common/original/molecule/NavListItem'
import { TranslatableText } from '../../../../localization/component/atom/TranslatableText'
import { mergeTranslationWithObjects } from '../../../../localization/util/localization.helper'
import { useUserStore } from 'src/feature/user/store/user.store'
import { DefaultSiteMap } from 'src/feature/user/model/SiteMap'
// import { LocalizedString } from 'typesafe-i18n'

export const MainLinks = observer(() => {
  const { translation } = useLocalizationStore()
  const { sitemap } = useUserStore()
  const navLinks = {
    // company: {
    //   label: sitemap?.modules.find(m => m.key === 'company')?.name || translation.components.header.companyDefaultLink() as LocalizedString,
    //   props: {
    //     link: appRoutePath.companyPage,
    //   },
    // },
    myGrowth: {
      props: {
        link: appRoutePath.userDashboard,
      },
    },
    myTeam: {
      props: {
        link: appRoutePath.administratorDashboard,
      },
    },
    // blog: {
    //   props: {
    //     link: appRoutePath.blog,
    //   },
    // },
    eLearning: {
      props: {
        link: appRoutePath.eLearning,
      },
    },
    eLearningV2: {
      props: {
        link: appRoutePath.learning,
      },
    },
    // payroll: {
    //   props: {
    //     link: appRoutePath.payroll,
    //   },
    // },
    // knowledge: {
    //   props: {
    //     link: appRoutePath.faq,
    //   },
    // },
    forum: {
      props: {
        link: appRoutePath.forum,
      },
    },
    controlpanel: {
      props: {
        link: appRoutePath.controlpanelOverview,
      },
    },
  }

  const nav = mergeTranslationWithObjects(translation.components.header.nav, navLinks) as unknown as HeaderNavModel
  const effectiveSitemap = {
    modules: [
      ...(DefaultSiteMap.modules || []), // Include DefaultSiteMap modules
      ...(sitemap?.modules || []), // Include sitemap modules
    ],
  }
  const list = Object.entries(nav).filter(n => effectiveSitemap.modules.some(m => m.key === n[0]))

  return (
    <>
      {list.map(([id, { label, props }]) => {
        return (
          <NavListItem className="third-step"
            key={id}
            textValue={label}
            isMain={true}
            {...props}
          >
            <TranslatableText component={'p'} className={LINK_LABEL_CLASS_NAME} textValue={label} />
          </NavListItem>
        )
      })}
    </>
  )
})
