import { observer } from 'mobx-react-lite'
import { Box, CircularProgress, circularProgressClasses, Collapse, Fade, useTheme } from '@mui/material'
import { Palette } from 'src/feature/theme/model/Palette'
import { AppBox } from 'src/component/common/analog/molecule/AppBox'
import { useSkillAssessmentStore } from '../../skill-assessment/store/skill-assessment.store'
import { useEffect, useState } from 'react'
import { SkillSet } from '../../skill-assessment/model/skill-assessment-model'
import { useNavigate } from 'react-router-dom'
import { appRoutePath } from 'src/feature/router/util/app-route-path'
import { Caption } from 'src/feature/localization/component/atom/Caption'
import { AppButton } from 'src/component/common/analog/molecule/button/AppButton'

export const SkillGapAnalysis = observer(() => {
  const [showGaps, setShowGaps] = useState<boolean>(false)
  const skillAssessmentStore = useSkillAssessmentStore()
  const navigate = useNavigate()
  const theme = useTheme()
  const { isLoading } = skillAssessmentStore.getGenericFieldsState('getSkillSets')

  useEffect(() => {
    skillAssessmentStore.skillSets = undefined
    skillAssessmentStore.getSkillSets()
  }, [])

  return (
    <>
      {!isLoading && skillAssessmentStore.skillSets?.category_description && <Collapse in={true}>
        <Box sx={styles.root}>
          <AppBox sx={styles.ratingWrapper}>
            {skillAssessmentStore.skillSets?.skills?.map((skill: SkillSet, index: number) => (
              <Box sx={{ position: 'relative', display: 'inline-flex', width: '200px', height: '200px' }} key={skill.id}>
                <Fade in={true} timeout={theme.transitions.duration.standard}>
                  <CircularProgress variant="determinate" value={100} sx={{
                    ...styles.circlePosition,
                    position: 'absolute',
                    color: Palette.BRIGHT_GREY_2,
                    transition: theme.transitions.create('all'),
                  }} size={200} thickness={4.2} />
                </Fade>
                {/* Selected level ring */}
                <Fade in={showGaps} timeout={theme.transitions.duration.standard}>
                  <CircularProgress variant="determinate" sx={{
                    ...styles.circlePosition,
                    color: (((skillAssessmentStore.skillSets?.skills?.[index]?.expected_set_level || 0) / 5) * 100) <= (((skillAssessmentStore.skillSets?.skills?.[index]?.selectedSetLevel || 0) / 5) * 100 || 0) ? '#008000' : Palette.DANGER,
                    animationDuration: '600ms',
                    position: 'absolute',
                    [`& .${circularProgressClasses.circle}`]: {
                      strokeLinecap: 'round',
                    },
                    transition: theme.transitions.create('all'),
                  }} size={200} thickness={4.2} value={((skillAssessmentStore.skillSets?.skills?.[index]?.expected_set_level || 0) / 5) * 100} />
                </Fade>
                {/* Expected level ring */}
                <Fade in={true} timeout={theme.transitions.duration.standard}>
                  <CircularProgress variant="determinate" sx={{
                    ...styles.circlePosition,
                    color: '#36bbaf8c',
                    animationDuration: '600ms',
                    position: 'absolute',
                    [`& .${circularProgressClasses.circle}`]: {
                      strokeLinecap: 'round',
                    },
                    transition: theme.transitions.create('all'),
                  }} size={200} thickness={4.2} value={((skillAssessmentStore.skillSets?.skills?.[index]?.selectedSetLevel || 0) / 5) * 100} />
                </Fade>
                <Fade in={true} timeout={theme.transitions.duration.standard}>
                  <Box
                    sx={{
                      ...styles.circlePosition,
                      position: 'absolute',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      transition: theme.transitions.create('all'),
                    }}
                  >
                    <Caption
                      variant="caption"
                      component="div"
                      color="text.primary"
                      sx={{ fontWeight: '500' }}
                    >
                      <AppBox sx={styles.circleContainer}>
                        <Box sx={styles.wordWrapper}>{skill.setName}</Box>
                      </AppBox>
                    </Caption>
                  </Box>
                </Fade>
              </Box>
            ))}
          </AppBox>
          <AppBox sx={{ flexDirection: { xs: 'column', md: 'column', lg: 'row' }, justifyContent: 'center', alignItems: 'flex-start', columnGap: '0.5rem' }}>
            {[{ color: Palette.DANGER, label: 'Expected Rating' }, { color: '#008000', label: 'Matched Rating' }, { color: '#36bbaf8c', label: 'Your Rating' }].map(rating => (
              <AppBox sx={{ gap: '0.5rem' }} key={rating.label}>
                <AppBox sx={{ width: '1rem', height: '1rem', backgroundColor: rating.color, borderRadius: '50%' }} />
                <Caption variant="caption" component="div">{rating.label}</Caption>
              </AppBox>
            ))}
          </AppBox>
          <AppBox sx={styles.buttonWrapper}>
            <AppButton variant='contained' color="primary" onClick={() => setShowGaps(!showGaps)}>{!showGaps ? 'Show GAPS' : 'Hide GAPS'}</AppButton>
            <AppButton variant='contained' color="primary" onClick={() => navigate(appRoutePath.skillAssessment)}>Rate Skill</AppButton>
          </AppBox>
        </Box>
      </Collapse>}
    </>
  )
})

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    rowGap: '2rem',
    padding: { xs: '0 1rem', sm: '0 2rem', lg: '0 3rem' },
  },
  ratingWrapper: {
    display: 'grid',
    gridTemplateColumns: { xs: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)', lg: 'repeat(5, 1fr)' },
    justifyContent: 'center',
    rowGap: '1.5rem',
    columnGap: '2.5rem',
  },
  circleContainer: {
    displayDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '10em',
  },
  circlePosition: {
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
  wordWrapper: {
    textAlign: 'center',
    wordWrap: 'break-word',
  },
  buttonWrapper: {
    display: 'grid',
    gridTemplateColumns: { xs: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)', lg: 'repeat(2, 1fr)' },
    gap: 10,
    width: { xs: '70%', md: '50%', lg: '30%' },
  },
}