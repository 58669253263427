import { Box } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { GetStarted } from '../component/GetStarted'
import { SkillGapAnalysis } from '../component/SkillGapAnalysis'
import { RecommendedCourses } from 'src/feature/e-learning/component/organism/RecommendedCourses'
import { CoursePlanPage } from 'src/feature/profile/page/CoursePlanPage'
import { RecommendedCategories } from '../component/RecommendedCategories'

export const UserDashboardPage = observer(() => {
  return (
    <Box sx={styles.root}>
      <GetStarted />
      <SkillGapAnalysis />
      <CoursePlanPage />
      <RecommendedCourses />
      <RecommendedCategories />
    </Box>
  )
})

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: '3rem',
  },
}
