import { useLocalizationStore } from '../../localization/store/localization.store'
import { observer } from 'mobx-react-lite'
import { useELearningStore } from 'src/feature/e-learning/store/e-learning.store'
import { useEffect } from 'react'
import { CoursePlanList } from 'src/feature/e-learning/component/organism/CoursePlanList'
import { Box } from '@mui/material'

export const CoursePlanPage = observer(() => {
  const { translation } = useLocalizationStore()
  const { coursePlan } = translation.pages
  const eLearningStore = useELearningStore()
  const { isLoading } = eLearningStore.getGenericFieldsState('updatePersonalCoursePlan')
  
  useEffect(() => {
    eLearningStore.updatePersonalCoursePlan()
  }, [])

  return (
    <Box sx={styles.root}>
      {!isLoading && eLearningStore.personalCourcePlan && <CoursePlanList title={coursePlan.assignedCourses()} items={eLearningStore.personalCourcePlan.filter(p => !p.progress && !p.isUserSelected)} />}
      {!isLoading && eLearningStore.personalCourcePlan && <CoursePlanList title={coursePlan.startedCourses()} items={eLearningStore.personalCourcePlan.filter(p => p.progress && !p.progress.completed)} />}
      {!isLoading && eLearningStore.personalCourcePlan && <CoursePlanList title={coursePlan.finishedCourses()} items={eLearningStore.personalCourcePlan.filter(p => p.progress && p.progress.completed)} />}
      {!isLoading && eLearningStore.personalCourcePlan && <CoursePlanList title={coursePlan.selectedCourses()} items={eLearningStore.personalCourcePlan.filter(p => !p.progress && p.isUserSelected)} />}
    </Box>
  )
})

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    padding: '0 12rem',
    gap: 20,
  },
}
