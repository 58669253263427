import { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useLocalizationStore } from 'src/feature/localization/store/localization.store'
import { Caption } from 'src/feature/localization/component/atom/Caption'
import { Title2 } from 'src/feature/localization/component/atom/Title2'
import { AppCarousel } from 'src/component/common/analog/molecule/carousel/AppCarousel'
import { Palette } from 'src/feature/theme/model/Palette'
import { AppBox } from 'src/component/common/analog/molecule/AppBox'
import { CourseListPresentationItem } from 'src/feature/e-learning/component/organism/CourseListPresentationItem'
import { Box } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { AppButton } from 'src/component/common/analog/molecule/button/AppButton'
import { appRoutePath } from 'src/feature/router/util/app-route-path'
import { useDashboardStore } from '../store/dashboard.store'
import { CourseListItem } from 'src/feature/e-learning/model/ELearningCourseListItem'

export type RecommendedCategoryCoursesProps = {
  category: string
}

export const RecommendedCategoryCourses = observer((props: RecommendedCategoryCoursesProps) => {
  const dashboardStore = useDashboardStore()
  const { translation, locale } = useLocalizationStore()
  const navigate = useNavigate()
  const [courses, setCourses] = useState<CourseListItem[]>([])
  const texts = translation.pages.eLearning
  const { categories } = translation.pages.eLearning
  type ObjectKey = keyof typeof categories
  
  useEffect(() => {
    getRandomCategoryCourses(props.category, locale)
  }, [])

  const getRandomCategoryCourses = async (categoryTitle: string, lang: string) => {
    const data = await dashboardStore.getRandomCategoryCourses(categoryTitle, lang)
    setCourses(data)
  }

  return (
    <Box sx={styles.root}>
      <Title2 m={0} style={{ textAlign: 'center', fontSize: '2.5rem' }}>{categories[props.category as ObjectKey].title()}</Title2>
      <AppBox sx={styles.container}>
        {courses && courses.length !== 0 && <AppCarousel showDots={true} showProgressBar={false}>
          {courses.map(c => <CourseListPresentationItem item={c} key={c.id} />)}
        </AppCarousel>}
        {courses && courses.length === 0 && <Caption textValue={texts.missingCourse} />}
      </AppBox>
      <AppBox sx={{ justifyContent: 'center' }}>
        <AppButton variant='contained' color="primary" onClick={() => navigate(appRoutePath.learning)}>Show all recommended</AppButton>
      </AppBox>
    </Box>
  )
})

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
  },
  container: {
    flexDirection: 'column',
    overflow: 'hidden',
    borderRadius: '0.75rem',
    border: `1px solid ${Palette.HAN_BLUE}40`,
    padding: '0.75rem 0.75rem 0.4rem 0.75rem',
    gap: 5,
  },
}
