import { Box, Fade } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { AppBox } from 'src/component/common/analog/molecule/AppBox'
import { AppPaper } from 'src/component/common/analog/molecule/AppPaper'
import { TitleWithTooltip } from './TitleWithTooltip'
import { MetricWithValue } from './MetricWithValue'
import { useDashboardStore } from '../store/dashboard.store'
import { useEffect } from 'react'
import { useELearningStore } from 'src/feature/e-learning/store/e-learning.store'

export const GrowthCard = observer(() => {
  const dashboardStore = useDashboardStore()
  const eLearningStore = useELearningStore()

  useEffect(() => {
    if (!dashboardStore.teamCourseOverview) {
      dashboardStore.getTeamCourseOverview()
    }
    if (!eLearningStore.personalCourcePlan) {
      eLearningStore.updatePersonalCoursePlan()
    }
  }, [dashboardStore.teamCourseOverview, eLearningStore.personalCourcePlan])

  return (
    <Box sx={styles.root}>
      <AppBox sx={styles.mainContainer}>
        <Fade in={true} timeout={500}>
          <AppPaper sx={styles.cardContainer}>
            <TitleWithTooltip title={'Team Growth'} tooltip={'The growth of the team over time.'} />
            <AppBox sx={styles.metricsWrapper}>
              <MetricWithValue label={'Total Users'} value={dashboardStore.teamCourseOverview?.totalUsers || 0} />
              <MetricWithValue label={'Active Users'} value={dashboardStore.teamCourseOverview?.activeUsers || 0} />
              <MetricWithValue label={'Inactive Users'} value={dashboardStore.teamCourseOverview?.inactiveUsers || 0} />
            </AppBox>
          </AppPaper>
        </Fade>
        <Fade in={true} timeout={500}>
          <AppPaper sx={styles.cardContainer}>
            <TitleWithTooltip title={'Personal Growth'} tooltip={'The growth of the user over time.'} />
            <AppBox sx={styles.metricsWrapper}>
              <MetricWithValue label={'Courses Left'} value={(eLearningStore.personalCourcePlan?.length || 0) - (eLearningStore.personalCourcePlan?.filter(p => p.progress && p.progress.completed).length || 0) || 0} />
              <MetricWithValue label={'Completed Courses'} value={(eLearningStore.personalCourcePlan?.filter(p => p.progress && p.progress.completed).length) || 0} />
              <MetricWithValue label={'Ongoing Courses'} value={(eLearningStore.personalCourcePlan?.filter(p => p.progress && !p.progress.completed).length) || 0} />
            </AppBox>
          </AppPaper>
        </Fade>
      </AppBox>
    </Box>
  )
})

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
    alignItems: 'center',
  },
  mainContainer: {
    display: 'grid',
    gridTemplateColumns: { xs: 'repeat(1, 1fr)', md: 'repeat(1, 1fr)', lg: 'repeat(2, 1fr)' },
    gap: 20,
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 10,
    padding: '2.5rem',
    borderRadius: '1rem',
    '&:hover': {
      boxShadow: '0 0 1em 0 rgba(0, 0, 0, 0.1)',
    },
  },
  metricsWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 50,
  },
}
