import { appRoutePath } from '../../router/util/app-route-path'
import { useLocalizationStore } from '../../localization/store/localization.store'
import { observer } from 'mobx-react-lite'
import { NavModel } from '../../../model/Nav'
import { Sidebar } from '../../sidebar/component/Sidebar'
import { mergeTranslationWithObjects } from '../../localization/util/localization.helper'
import { useBreakpoints } from '../../theme/hook/useBreakpoints'
import { useUserStore } from 'src/feature/user/store/user.store'
import { useEffect } from 'react'
import { AppCircleLoader } from 'src/component/common/analog/molecule/loader/AppCircleLoader'
import { useAuthStore } from 'src/feature/auth/store/auth.store'
import { OrganisationRole } from '../model/OrganisationRole'
import { Box } from '@mui/material'

export const ControlPanelSidebar = observer(() => {
  const { translation } = useLocalizationStore()
  const { isMd } = useBreakpoints()
  const userStore = useUserStore()
  const authStore = useAuthStore()
  const { isLoading } = userStore.getGenericFieldsState('handleGetOrganisation')

  const { controlPanelSidebar } = translation.components
  const navLinks: NavModel<'props.link'> = {
    controlpanelOverview: { props: { link: appRoutePath.controlpanelOverview } },
    controlpanelUsers: { props: { link: appRoutePath.controlpanelUsers, hidden: !authStore.hasRole(OrganisationRole.SystemAdministrator) } },
    controlpanelOrganisation: { props: { link: appRoutePath.controlpanelOrganisation, hidden: !authStore.hasRole(OrganisationRole.SystemAdministrator) } },
    controlpanelModules: { props: { link: appRoutePath.controlpanelModules, hidden: !authStore.hasRole(OrganisationRole.SystemAdministrator) } },
    controlpanelSystem: { props: { link: appRoutePath.controlpanelSystem, hidden: !authStore.hasRole(OrganisationRole.SystemAdministrator) } },
  }

  const desktopNav = mergeTranslationWithObjects(controlPanelSidebar.nav, navLinks) as unknown as NavModel

  const { ...mobileNav } = desktopNav
  const currentNav = isMd ? mobileNav : desktopNav

  useEffect(() => {
    userStore.handleGetOrganisation()
  }, [])

  if (isLoading) {
    return <AppCircleLoader isShowing={true} />
  }

  return (
    <Box sx={{ padding: '0 3rem' }}>
      <Sidebar
        nav={currentNav}
        baseSidebarProps={{
          title: userStore.organisation?.name || '',
        }}
      />
    </Box>
  )
})