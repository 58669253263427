import { Box, Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { Title3 } from 'src/feature/localization/component/atom/Title3'

export type MetricWithValueProps = {
  label: string,
  value: number | string,
  size?: 'small' | 'large',
  color?: string,
}

export const MetricWithValue = observer((props: MetricWithValueProps) => {
  return (
    <Box key={props.label} sx={styles.root}>
      <Typography sx={{
        fontSize: props.size === 'small' ? '2.25em' : '4.5em',
        color: props.color ? props.color : 'primary.main',
      }}>{props.value}</Typography>
      <Title3 m={0}>{props.label}</Title3>
    </Box>
  )
})

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
}
