import { observer } from 'mobx-react-lite'
import { AppBox } from 'src/component/common/analog/molecule/AppBox'
import { AppPaper } from 'src/component/common/analog/molecule/AppPaper'
import { Title2 } from 'src/feature/localization/component/atom/Title2'
import { MetricWithValue } from './MetricWithValue'
import { useEffect } from 'react'
import { useDashboardStore } from '../store/dashboard.store'
import { Line, LineChart, ResponsiveContainer } from 'recharts'
import { Fade } from '@mui/material'

export type UserCardsProps = {
  levelId: string
  userId: string
}

export const UserCards = observer(({ levelId, userId }: UserCardsProps) => {
  const dashboardStore = useDashboardStore()
  const data = [
    { date: '2024-01-01', value: Math.floor(Math.random() * 1000) },
    { date: '2024-01-02', value: Math.floor(Math.random() * 1000) },
    { date: '2024-01-03', value: Math.floor(Math.random() * 1000) },
    { date: '2024-01-04', value: Math.floor(Math.random() * 1000) },
    { date: '2024-01-05', value: Math.floor(Math.random() * 1000) },
    { date: '2024-01-06', value: Math.floor(Math.random() * 1000) },
    { date: '2024-01-07', value: Math.floor(Math.random() * 1000) },
  ]

  useEffect(() => {
    dashboardStore.getUserOverview(levelId, userId)
  }, [levelId])

  return (
    <Fade in={true} timeout={500}>
      <AppPaper key={levelId} sx={styles.root}>
        <Title2 m={0} style={{ fontSize: '1.5rem' }}>{dashboardStore.userOverview?.[userId]?.userName}</Title2>
        <AppBox sx={styles.metricsWrapper}>
          <MetricWithValue size='small' label={'Total Courses'} value={dashboardStore.userOverview?.[userId]?.TotalCourses || 0} />
          <MetricWithValue size='small' label={'Completed'} value={dashboardStore.userOverview?.[userId]?.CompletedCourses || 0} />
          <MetricWithValue size='small' label={'Ongoing'} value={dashboardStore.userOverview?.[userId]?.InProgressCourses || 0} />
          <MetricWithValue size='small' label={'Given Courses'} value={dashboardStore.userOverview?.[userId]?.CoursesGiven || 0} />
          <MetricWithValue size='small' label={'Completed'} value={dashboardStore.userOverview?.[userId]?.CoursesCompleted || 0} />
          <MetricWithValue size='small' label={'Ongoing'} value={dashboardStore.userOverview?.[userId]?.CoursesInProgress || 0} />
          <ResponsiveContainer style={{ position: 'absolute' }} width="100%" height="100%">
            <LineChart data={data}>
              <Line
                type="monotone"
                dataKey="value"
                stroke="#8884d8"
                strokeWidth={2}
                isAnimationActive={true}
                animationBegin={0}
                animationDuration={1500}
                animationEasing="ease-in-out"
                opacity={0.2}
              />
            </LineChart>
          </ResponsiveContainer>
        </AppBox>
      </AppPaper>
    </Fade>
  )
})

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 20,
    padding: '3.5rem 2.5rem 1.5rem 2.5rem',
    borderRadius: '1rem',
    '&:hover': {
      boxShadow: '0 0 1em 0 rgba(0, 0, 0, 0.1)',
    },
  },
  metricsWrapper: {
    display: 'grid',
    gridTemplateColumns: { xs: 'repeat(3, 1fr)', md: 'repeat(3, 1fr)', lg: 'repeat(3, 1fr)' },
    columnGap: 10,
    rowGap: 5,
    position: 'relative',
  },
}
