import { Box } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { MetricWithValue } from './MetricWithValue'
import { useDashboardStore } from '../store/dashboard.store'

export type UserMetricsProps = {
  levelId: string
}

export const UserMetrics = observer(({ levelId }: UserMetricsProps) => {
  const dashboardStore = useDashboardStore()

  return (
    <Box sx={styles.root}>
      <MetricWithValue size='large' label={'Total Users'} value={dashboardStore.teamOverview?.[levelId]?.totalUsers || 0} />
      <MetricWithValue size='large' label={'Active Users'} value={dashboardStore.teamOverview?.[levelId]?.activeUsers || 0} />
      <MetricWithValue size='large' label={'Inactive Users'} value={dashboardStore.teamOverview?.[levelId]?.inactiveUsers || 0} />
      <MetricWithValue size='large' label={'Courses Given'} value={dashboardStore.teamOverview?.[levelId]?.assignedCourses || 0} />
      <MetricWithValue size='large' label={'Users Complete'} value={dashboardStore.teamOverview?.[levelId]?.totalCoursesUsersCompleted || 0} />
      <MetricWithValue size='large'
        color={(dashboardStore.teamOverview?.[levelId]?.completionRate || 0) < 50 ? 'error.main' : 'success.main'}
        label={'Complete Rate'}
        value={`${dashboardStore.teamOverview?.[levelId]?.completionRate || 0}%`}
      />
    </Box>
  )
})

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'row',
    gap: '5rem',
  },
}