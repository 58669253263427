import { axiosInstance } from 'src/util/axios/instance.axios'
import { ProfessionType, SkillCategory } from '../model/skill-assessment-model'

export async function getSkillSets(): Promise<SkillCategory> {
  const { data } = await axiosInstance.request<SkillCategory>({
    url: `/skill-analysis/skill-sets/`,
  })

  return data
}

export async function addSkillAssessment(set_id: string, selected_set_level: number): Promise<any> {
  const { data } = await axiosInstance.request({
    url: '/skill-analysis/skill-assessment',
    method: 'post',
    data: {
      set_id,
      selected_set_level,
    },
  })

  return data
}

export async function getProfessions(): Promise<ProfessionType[]> {
  const { data } = await axiosInstance.request({
    url: '/skill-analysis/skill-data',
  })

  return data
}

export async function getSkillCategory(levelId: string): Promise<SkillCategory> {
  const { data } = await axiosInstance.request<SkillCategory>({
    url: `/skill-analysis/skill-category/${levelId}`,
  })

  return data
}

export async function addSkillCategory(profession: string, levelId: string): Promise<any> {
  const { data } = await axiosInstance.request({
    url: `/skill-analysis/skill-category/`,
    method: 'post',
    data: {
      profession,
      levelId,
    },
  })

  return data
}

export async function addExpectedLevel(set_id: string, set_name: string, expected_set_level: number): Promise<any> {
  const { data } = await axiosInstance.request({
    url: '/skill-analysis/expected-level',
    method: 'post',
    data: {
      set_id,
      set_name,
      expected_set_level,
    },
  })

  return data
}
