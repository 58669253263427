import { Theme } from '@mui/material'
import { AppLink, AppLinkProps } from '../../analog/molecule/link/AppLink'
import { useLocation } from 'react-router-dom'
import { handlePassedSx } from '../../../../feature/theme/model/Sx'
import { AppListItem } from '../../analog/molecule/list/AppListItem'
import { isPathActive } from '../../../../model/Path'
import { SxHelperStyle } from '../../../../feature/theme/style/sx-helper.style'

export function NavListItem(props: AppLinkProps) {
  const { pathname } = useLocation()
  const { sx = [], isMain = false, ...rest } = props
  const mainPage = props.link ? isMain && props.link.split('/').length > 2 ? props.link.substring(0, props.link.lastIndexOf('/') + 1) : props.link : props.mail

  return (
    <AppListItem
      sx={styles.root}
      selected={isPathActive(pathname, mainPage)}
    >
      <AppLink
        underline={'none'}
        sx={[
          styles.link,
          ...handlePassedSx(sx),
        ]}
        {...rest}
      />
    </AppListItem>
  )
}

export const LINK_LABEL_CLASS_NAME = 'link-label'

const styles = {
  root: ({ palette }: Theme) => ({
    ...SxHelperStyle.noIndents,
    [`& .${LINK_LABEL_CLASS_NAME}`]: {
      fontSize: '1em',
      position: 'relative',
      whiteSpace: 'nowrap',
      transition: 'all 0.1s ease-in',
    },
    '&:hover': {
      [`& .${LINK_LABEL_CLASS_NAME}`]: {
        color: palette.primary.main,
      },
    },
    '&.Mui-selected': {
      background: 'transparent',
      [`& .${LINK_LABEL_CLASS_NAME}`]: {
        color: palette.primary.main,
      },
    },
  }),
  link: ({ palette }: Theme) => ({
    fontSize: '1em',
    color: palette.common.black,
    textAlign: 'left',
  }),
}
