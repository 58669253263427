import { Header } from '../../../feature/header/component/original/organism/Header'
import { Footer } from '../../../feature/footer/component/Footer'
import { ChildrenProps } from '../../../model/React'
import { AppBox } from '../../common/analog/molecule/AppBox'
import { useViewportSizes } from '../../../hook/sizes/useViewportSizes'
import { NodeId } from '../../../util/node-id'
import { CssUnit } from '../../../feature/theme/model/CssUnit'
import { useScrollSizes } from '../../../hook/sizes/useScrollSizes'
import { SxHelperStyle } from '../../../feature/theme/style/sx-helper.style'
import { findPercentageOfNumber, Percentage } from '../../../model/Number'
import { AppContainer } from '../../common/analog/molecule/AppContainer'
import { Theme } from '@mui/material'

const NORMAL_CONTENT_HEIGHT: Percentage = 58

export function BaseLayout(props: ChildrenProps) {
  const header = useScrollSizes(NodeId.HEADER)
  const content = useScrollSizes(NodeId.CONTENT)
  const viewport = useViewportSizes()

  const areHeaderAndContentLessThanViewport = (header?.scroll.height + content?.scroll.height) < viewport?.client.height
  const isContentLongerThanViewport = content?.scroll.height > viewport?.scroll.height
  const normalizedContentHeight = `${(findPercentageOfNumber(viewport?.client.height, NORMAL_CONTENT_HEIGHT)) - header?.scroll.height}px`
  const minContentHeight = isContentLongerThanViewport ? 'unset' : areHeaderAndContentLessThanViewport ? normalizedContentHeight : '100vh'

  return (
    <AppBox sx={styles.root}>
      <Header />

      <AppContainer
        id={NodeId.CONTENT}
        sx={styles.content(minContentHeight)}
      >
        {props.children}
      </AppContainer>
      <Footer />
    </AppBox>
  )
}

const styles = {
  root: {
    ...SxHelperStyle.flexColumn,
    position: 'relative',
    minHeight: '100%',
  },
  content: (minHeight: CssUnit) => ({ breakpoints }: Theme) => ({
    margin: '0 auto',
    width: 1,
    flexGrow: 2,
    minHeight,
    [breakpoints.up('xs')]: {
      padding: 0,
    },
    maxWidth: 'unset',
  }),
}
