import { observer } from 'mobx-react-lite'
import { ELearningCourseCategory } from '../../model/ELearningCourseCategory'
import { useLocalizationStore } from 'src/feature/localization/store/localization.store'
import { useNavigate } from 'react-router-dom'
import { appRoutePath } from 'src/feature/router/util/app-route-path'
import { Avatar, Chip } from '@mui/material'

export type CategoryItemProp = {
  item: ELearningCourseCategory
  index: number
}

export const CategoryItem = observer(({ item, index }: CategoryItemProp) => {
  const { translation } = useLocalizationStore()
  const navigate = useNavigate()
  const { categories } = translation.pages.eLearning
  type ObjectKey = keyof typeof categories
  const texts = categories[item.titleLocale as ObjectKey]

  return (
    <Chip
      key={index}
      avatar={<Avatar sx={styles.avatar}>{texts.title().split(' ')
        .map(word => word[0])
        .join('')}</Avatar>}
      sx={{ justifyContent: 'start', height: '1.7rem' }}
      label={texts.title()}
      onClick={() => navigate(appRoutePath.learningCategory(item.titleLocale))}
    />
  )
})

const styles = {
  avatar: {
    backgroundColor: 'primary.main',
    marginLeft: '0px !important',
    padding: '0.75rem',
  },
}
